<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6"  class="white shadow1 rounded">
                <v-data-table
                    class="custom-data-table"
                    :headers="boxSizeHeaders"
                    :items="$store.getters['box_size/box_sizes']"
                    :search="searchBoxSize"
                    :loading="$store.getters['box_size/loadingBoxSizes']" 
					loading-text="Loading... Please wait"
                >
                    <template v-slot:top>
                        <v-toolbar
                            dense
                            color="white"
                            :elevation="0"
                            height="50"
                        >
                            <v-toolbar-title class="subtitle-1"
                                >Box Size List</v-toolbar-title
                            >
                            

                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Box Size"
                                    append-icon="mdi-magnify"
                                    style="width: 300px"
                                    v-model="searchBoxSize"
                                >
                                </v-text-field>
                            </v-form>
                            <v-spacer></v-spacer>
                            <box-size-dialog :icon="true" ref="boxSizeDialog"></box-size-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="editBoxSize(item)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-circle-edit-outline</v-icon
                                >
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="boxSizeId = item.id;$refs.confirmDialog.dialog = true"
                                    color="error"
                                    v-on="on"
                                    >mdi-delete-circle-outline</v-icon
                                >
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteBoxSize"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "../../components/confirm-dialog.component";
import boxSizeDialog from "../../components/box-size-dialog.component";

export default {
    name: 'BoxSize',

    components: {
		"confirm-dialog": confirmDialog,
		"box-size-dialog": boxSizeDialog,
	},

    data() {
        return {
            boxSizeHeaders: [
                { text: 'SL', value: 'sl' },
                { text: 'Name', value: 'name' },
                { text: 'Amount', value: 'amount' },
                { text: 'Action', value: 'action' },
            ],
            searchBoxSize: "",
            boxSizeId: null,
            loading: false,
        }
    },

    created() {
        this.$store.dispatch("box_size/getBoxSizes");
    },

    methods: {
        editBoxSize(box_size){
            Object.keys(this.$refs.boxSizeDialog.box_size).forEach(key => {
                this.$refs.boxSizeDialog.box_size[key] = box_size[key];
            })
            this.$refs.boxSizeDialog.showBoxSizeDialog = true;
        },
        deleteBoxSize(){
            this.$store.dispatch('box_size/deleteBoxSize', this.boxSizeId);
            this.$refs.confirmDialog.dialog = false;
        }
    }
}
</script>

<style>

</style>